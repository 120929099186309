import React, { FC, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Layout } from "../../components/Layout/Layout";

import { AppDispatch, RootState } from "../../store/store";

import { fetchColumns } from "../../store/Kanban/Columns/Columns.slice";
import { KanbanBoard } from "../../components/KanbanBoard/KanbanBoard";

import { fetchGoals } from "../../store/Kanban/Goals/Goals.slice";

import { Goal } from "../../components/Goal/Goal";

import { Goal as GoalType } from "../../api/types";

import { Navbar } from "../../components/Navbar/Navbar";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { PageHeader } from "../../components/PageHeader/PageHeader";

import { ActionButton } from "../../components/Navbar/Navbar.types";

import { NotesModal } from "../../components/NotesModal/NotesModal";

import { setCurrentEmployeeId } from "../../store/EmployeesManagmentPanel/EmployeesManagmentPanel.slice";

import styles from "./KanbanPage.module.scss";

export const KanbanPage: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { id: matrixId } = useSelector((state: RootState) => state.currentMatrix);
    const { goals } = useSelector((state: RootState) => state.goals);
    const { user } = useSelector((state: RootState) => state.userStore);

    useEffect(() => {
        dispatch(setCurrentEmployeeId(undefined));
        if (matrixId) {
            dispatch(fetchGoals(matrixId));
        }
        dispatch(fetchColumns());
    }, [matrixId]);

    const handleBack = () => {
        navigate("/matrixes");
    };

    const goal = goals[0] as GoalType;

    const actionButtons: ActionButton[] = [
        {
            text: t("globals.back"),
            action: handleBack,
            tooltip: t("globals.backTooltip"),
        },
    ];

    return (
        <>
            <Navbar actionButtons={actionButtons} />
            <Layout>
                <div className={styles.wrapper}>
                    <div className={goal ? styles.pageEditableGoalHeaderWrapper : styles.pageAddGoalHeaderWrapper}>
                        <div>
                            <PageHeader title={`${t("globals.welcome")} ${user.first_name} ${user.last_name}`} />
                        </div>
                        <div className={styles.rightWrapper}>
                            {matrixId && <Goal />}
                            {/* <NotesModal /> */}
                        </div>
                    </div>
                    <SectionHeader title={t("kanbanPage.pageTitle")} subTitle="" titleSize="huge" />
                    <KanbanBoard />
                </div>
            </Layout>
        </>
    );
};
